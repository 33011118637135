import * as types from "./reportsTypes";
import { axiosWithAuth } from "../../utils/auth";
import fileSaver from "../../utils/fileSaver";

export const getReports = (page = 1) => async (dispatch) => {
  dispatch({ type: types.BEGIN_REPORT_FETCH });

  try {
    const { data } = await axiosWithAuth().get(
      `/reports/transactions?page=${page}`
    );
    dispatch({
      type: types.REPORTS_FETCH_SUCCESS,
      payload: {
        reports: data.data,
        count: data.data.length,
      },
    });
  } catch (error) {
    dispatch({
      type: types.REPORT_FETCH_FAILED,
      payload: error,
    });
  }
};

export const getMoreReports = (page) => async (dispatch, getState) => {
  dispatch({ type: types.BEGIN_REPORT_FETCH });
  try {
    const { checkpoint } = getState().reports;
    const { data } = await axiosWithAuth().get(
      `/reports/transactions?checkpoint=${checkpoint}&page=${page}`
    );

    dispatch({
      type: types.REPORTS_FETCH_SUCCESS,
      payload: { reports: data.data, count: data.data.length },
    });
  } catch (error) {
    dispatch({
      type: types.REPORT_FETCH_FAILED,
      payload: error,
    });
  }
};

export const downloadReport = (period) => async (dispatch) => {
  dispatch({ type: types.BEGIN_REPORT_DOWNLOAD });
  try {
    const response = await axiosWithAuth().get(
      `/reports/transactions/${period}`,
      {
        responseType: "blob",
      }
    );
    console.log(response);
    await fileSaver(response.data, `${period}.xlsx`);
    dispatch({
      type: types.REPORT_DOWNLOAD_COMPLETE,
    });
  } catch (error) {
    alert(
      "An Error Occurred While Downloading excel data, please contact your administrator"
    );
    dispatch({
      type: types.REPORT_DOWNLOAD_FAILED,
      payload: error,
    });
  }
};

export const clearDownloadedReports = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_DOWNLOADS });
};
