import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

// Reducers
import userReducer from "../modules/user/userReducer";
import dashboardReducer from "../modules/dashboard/dashboardReducer";
import transactionsReducer from "../modules/transactions/transactionsReducer";
import reportsReducer from "../modules/reports/reportsReducer";

const rootReducer = combineReducers({
  user: userReducer,
  dashboard: dashboardReducer,
  transactions: transactionsReducer,
  reports: reportsReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
