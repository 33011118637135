import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";

// @material-ui/icons
import { Visibility, VisibilityOff, Warning } from "@material-ui/icons";
// Design components
import { GridItem, GridContainer } from "components/Grid";
import { Card, CardHeader, CardBody, CardFooter } from "components/Card";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Danger } from "components/Typography";
// Store Actions
import { attemptAuth } from "../../modules/user/userActions";
// Styles
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { error, loggedIn, loading } = user;

  const login = () => {
    dispatch(attemptAuth({ email, password }));
  };

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      {loggedIn && <Redirect to="/admin" />}
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <CardHeader color="primary" stats>
              <h3 className={classes.whiteCardTitle}>
                <small>Manager Login</small>
              </h3>
              <p className={classes.cardCategoryWhite}>
                Enter Login Details to Continue
              </p>
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Phone No."
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: false,
                }}
              />
              <CustomInput
                labelText="Password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: false,
                  type: `${showPassword ? "text" : "password"}`,
                  endAdornment: (
                    <InputAdornment
                      style={{ cursor: "pointer" }}
                      onClick={handleClick}
                      position="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </InputAdornment>
                  ),
                }}
              />
              <div className={`${classes.flex} ${classes.centerFlex}`}>
                <Button
                  disabled={loading}
                  onClick={login}
                  type="button"
                  color="rose"
                >
                  Login
                </Button>
              </div>
            </CardBody>
            {error?.message && (
              <CardFooter
                stats
                className={`${classes.flex} ${classes.centerFlex}`}
              >
                <div className={classes.stats}>
                  <Danger>
                    <Warning />
                  </Danger>
                  <span>{error.message}</span>
                </div>
              </CardFooter>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
export default Login;
