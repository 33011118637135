import * as types from "./reportsTypes";

const initialState = {
  loadingReport: false,
  loadingDownload: false,
  error: null,
  reports: [],
  report: [],
  downloadSuccessful: false,
  limit: 3,
  checkpoint: null,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.BEGIN_REPORT_FETCH:
      return {
        ...state,
        loadingReport: true,
        error: null,
      };
    case types.REPORTS_FETCH_SUCCESS:
      return {
        ...state,
        loadingReport: false,
        error: null,
        reports: [...state.reports, ...action.payload.reports],
        pages: action.payload.count,
        checkpoint: state.checkpoint
          ? state.checkpoint
          : action.payload.checkpoint,
      };
    case types.REPORT_FETCH_SUCCESS:
      return {
        ...state,
        loadingReport: false,
        error: null,
        report: action.payload,
      };
    case types.REPORT_FETCH_FAILED:
      return {
        ...state,
        loadingReport: false,
        error: action.payload.message,
      };
    case types.BEGIN_REPORT_DOWNLOAD:
      return {
        ...state,
        loadingDownload: true,
        error: null,
      };
    case types.REPORT_DOWNLOAD_FAILED:
      return {
        ...state,
        loadingDownload: false,
        error: action.payload.message,
      };
    case types.REPORT_DOWNLOAD_COMPLETE:
      return {
        ...state,
        loadingDownload: false,
        error: null,
        downloadSuccessful: true,
      };
    case types.CLEAR_DOWNLOADS:
      return {
        ...state,
        downloadSuccessful: false,
        download: [],
        loadingDownload: false,
      };
    default:
      return initialState;
  }
};

export default reportsReducer;
