import axios from "axios";
import * as CONFIG from "../config";

const TOKEN_KEY = "cfa8ebf4";
const GATEWAY_KEY = "x-gateway";

const baseUrl = CONFIG.BASE_URL;

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const getToken = () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token === null || !token) {
      return undefined;
    }
    return JSON.parse(token);
  } catch (error) {
    // if error decoding, clear what is in local storage with key
    clearLocalStorage();
    return undefined;
  }
};

export const getAuthorizationHeaders = () => {
  let x_gateway = localStorage.getItem(GATEWAY_KEY);
  x_gateway = JSON.parse(x_gateway);
  if (x_gateway) {
    return JSON.parse(x_gateway.id);
  }
  return null;
};

export const setToken = (payload) => {
  const item = JSON.stringify(payload);
  localStorage.setItem(TOKEN_KEY, item);
};

export const setGateway = (gateways) => {
  if (Array.isArray(gateways)) {
    let gateway = gateways[0];
    gateway = JSON.stringify(gateway);
    localStorage.setItem(GATEWAY_KEY, gateway);
  } else {
    const gateway = JSON.stringify(gateway);
    localStorage.setItem(GATEWAY_KEY, gateway);
  }
};

export const axiosWithAuth = () => {
  const x_gateway_id = getAuthorizationHeaders();
  return axios.create({
    headers: {
      "Content-Type": "application/json",
      "x-gateway-id": x_gateway_id,
    },
    baseURL: baseUrl,
  });
};

export const axiosWithoutAuth = () => {
  return axios.create({
    headers: {
      "Content-Type": "application/json",
    },
    baseURL: baseUrl,
  });
};
