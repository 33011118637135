import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import {
  closeTransaction,
  getSingleTransaction,
} from "modules/transactions/transactionsActions";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  list: {
    padding: theme.spacing(0),
  },
}));

function TransactionModal() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    txnId,
    modalOpen: open,
    singleTransaction,
    loadingSingle,
  } = useSelector((state) => state.transactions);
  const { vendResponse, customer } = singleTransaction;

  useEffect(() => {
    if (txnId) {
      dispatch(getSingleTransaction(txnId));
    }
  }, [txnId]);

  const handleClose = () => {
    dispatch(closeTransaction());
  };

  return (
    <>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        // keepMounted
        maxWidth={"sm"}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{ padding: "16px 24px 0px" }}
          id="alert-dialog-slide-title"
        >
          <Typography variant="h5" align="left">
            Transaction Details
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        {loadingSingle ? (
          <DialogContent>
            <Box
              style={{ display: "flex", justifyContent: "center" }}
              flexgrow={5}
            >
              <CircularProgress style={{ color: "green" }} size={30} />
            </Box>
          </DialogContent>
        ) : (
          <>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <List dense>
                    <ListItem disableGutters>
                      <ListItemText
                        primary="Reference"
                        secondary={singleTransaction.reference}
                      />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText
                        primary="Amount"
                        secondary={singleTransaction.amount}
                      />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText
                        primary="Service"
                        secondary={singleTransaction.service}
                      />
                    </ListItem>
                  </List>
                  {/* <TransactionDetails id={txnId} dispatch={dispatch} /> */}
                </Grid>
                <Grid item xs={6}>
                  <List dense>
                    <ListItem disableGutters>
                      <ListItemText
                        primary="Plan"
                        secondary={singleTransaction.plan}
                      />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText
                        primary="Status"
                        secondary={singleTransaction.status}
                      />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText
                        primary="Date"
                        secondary={singleTransaction.created_at}
                      />
                    </ListItem>
                  </List>
                  {/* <TransactionDetails id={txnId} dispatch={dispatch} /> */}
                </Grid>
              </Grid>
            </DialogContent>
            {vendResponse && (
              <>
                <DialogTitle style={{ padding: "0px 24px" }}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    align="left"
                    color="secondary"
                  >
                    Response
                  </Typography>
                </DialogTitle>
                <DialogContent style={{ padding: "0px 24px" }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <List dense className={classes.list}>
                        <ListItem disableGutters>
                          <ListItemText
                            primary="Token"
                            secondary={vendResponse.token}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText
                            primary="Receipt Number"
                            secondary={vendResponse.rct_num}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={6}>
                      <List dense>
                        <ListItem disableGutters>
                          <ListItemText
                            primary="DEBT"
                            secondary={"₦ " + vendResponse.debt_amt}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText
                            primary="VAT"
                            secondary={"₦ " + vendResponse.debt_amt}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </DialogContent>
              </>
            )}

            {customer && (
              <>
                <DialogTitle style={{ padding: "0px 24px" }}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    align="left"
                    color="secondary"
                  >
                    Customer
                  </Typography>
                </DialogTitle>
                <DialogContent style={{ padding: "0px 24px" }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <List dense>
                        <ListItem disableGutters>
                          <ListItemText
                            primary="Name"
                            secondary={customer.name}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText
                            primary="Email"
                            secondary={customer.email}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText
                            primary="Phone Number"
                            secondary={customer.phone}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={6}>
                      <List dense>
                        <ListItem disableGutters>
                          <ListItemText
                            primary="Wallet"
                            secondary={"₦ " + customer.wallet}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText
                            primary="DEBT"
                            secondary={"₦ " + customer.debt}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </DialogContent>
              </>
            )}
          </>
        )}
      </Dialog>
    </>
  );
}

export default TransactionModal;

/* 
                <Box marginY="15px" />

                <VendDetails id={id} vertical={"ELECTRICITY"} dispatch={dispatch} />
                <Box marginY="15px" />

                <TokenResponse id={id} /> 
                */
