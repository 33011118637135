import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
// import ReactExport from "react-data-export";
//  Design Components
import { GridContainer, GridItem } from "components/Grid";
import { CardHeader, Card } from "components/Card";
import {
  Typography,
  CardContent,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Fab,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  // Visibility,
  Save,
  CheckCircle,
  CloudDownload,
} from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
// Action Components
import {
  getReports,
  downloadReport,
  clearDownloadedReports,
} from "modules/reports/reportsActions";

// import ReportsPagination from "./ReportsPagination";

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  table: {
    minWidth: 650,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  wrapper: {
    marginLeft: theme.spacing(1),
    position: "relative",
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    // top: -1,
    left: -1,
    zIndex: 1,
  },
  buttonResize: {
    width: "37px",
    height: "30px",
  },
}));
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function Report() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    reports,
    loadingReport,
    loadingDownload,
    // download,
    downloadSuccessful,
    // error,
    // pages,
  } = useSelector((state) => state.reports);

  const [selectedReport, setSelectedReport] = useState(-1);
  // const [excelData, setExcelData] = useState([]);
  // const maxDataPerSheet = 3;
  // const [sheetIndex, setSheetIndex] = useState(0);
  // const [dataCount, setDataCount] = useState(0);
  // const [excelReady, setExcelReady] = useState(false);

  const clearDownloadReport = () => {
    setSelectedReport(-1);
    // setExcelReady(false);
    // setSheetIndex(0);
    // setExcelData([]);
    // setDataCount(0);
    dispatch(clearDownloadedReports());
  };
  const startDownloadReport = (period) => {
    clearDownloadReport();
    setSelectedReport(period);
    dispatch(downloadReport(period));
  };

  const downloadButtonClassname = clsx({
    [classes.buttonSuccess]: downloadSuccessful ? true : false,
    [classes.buttonResize]: true,
  });

  useEffect(() => {
    dispatch(getReports());
  }, []);
  // useEffect(() => {
  //   if (downloadSuccessful) {
  //     setDataCount(download.length);
  //   }
  // }, [download, downloadSuccessful]);
  // useEffect(() => {
  //   if (downloadSuccessful && sheetIndex <= dataCount) {
  //     const lastIndex = maxDataPerSheet + sheetIndex;
  //     const dataSet = [
  //       {
  //         columns: [
  //           { title: "Reference" },
  //           { title: "Amount" },
  //           { title: "Service" },
  //           { title: "Plan" },
  //           { title: "Gateway" },
  //           { title: "Customer ID" },
  //           { title: "Order ID" },
  //           { title: "Status" },
  //           { title: "Created At" },
  //           { title: "Last Update At" },
  //           { title: "Customer Reference" },
  //           { title: "Gateway Reference" },
  //           { title: "Order ID Status" },
  //           { title: "Response" },
  //         ],
  //         data: download.slice(sheetIndex, lastIndex).map((data) => {
  //           return [
  //             { value: data.reference },
  //             { value: data.amount },
  //             { value: data.service },
  //             { value: data.plan },
  //             { value: data.gateway },
  //             { value: data.customer_id },
  //             { value: data.order_id },
  //             { value: data.status },
  //             { value: data.created_at },
  //             { value: data.updated_at },
  //             { value: data.customer_ref },
  //             { value: data.gateway_ref },
  //             { value: data.order_id_status },
  //             { value: data.response },
  //           ];
  //         }),
  //       },
  //     ];

  //     setExcelData([...excelData, dataSet]);
  //     setSheetIndex(sheetIndex + maxDataPerSheet);
  //   }
  //   if (sheetIndex > dataCount) {
  //     setExcelReady(true);
  //   }
  // }, [downloadSuccessful, sheetIndex]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card variant="outlined">
          <CardHeader color="primary">
            <Box display="flex">
              <Box flexGrow={3}>
                <h4 className={classes.cardTitleWhite}>Reports</h4>
                <p className={classes.cardCategoryWhite}>
                  All Available Reports
                </p>
              </Box>
              <Box>
                {loadingReport && (
                  <CircularProgress style={{ color: "white" }} size={40} />
                )}
              </Box>
            </Box>
          </CardHeader>
          <CardContent>
            {!loadingReport && reports.length <= 0 ? (
              <Typography>Reports Are Currently Not Available.</Typography>
            ) : reports.length > 0 ? (
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Period</TableCell>
                      <TableCell>Total Transactions</TableCell>
                      <TableCell>₦ Total Amount</TableCell>
                      {/* <TableCell padding="none">View</TableCell> */}
                      <TableCell>
                        <CloudDownload />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reports.map((report, index) => {
                      const { period, count, sum } = report;
                      const date = new Date(period);
                      const month = date.toLocaleString("default", {
                        month: "long",
                      });
                      const year = date.getFullYear();
                      return (
                        <TableRow key={index}>
                          <TableCell>{`${month} ${year}`}</TableCell>
                          <TableCell>{count}</TableCell>
                          <TableCell>{sum}</TableCell>
                          <TableCell padding="none">
                            <div className={classes.wrapper}>
                              {downloadSuccessful &&
                              selectedReport === period ? (
                                <Fab
                                  aria-label="download"
                                  color="primary"
                                  className={downloadButtonClassname}
                                  onClick={clearDownloadReport}
                                >
                                  <CheckCircle />
                                </Fab>
                              ) : (
                                <Fab
                                  aria-label="save"
                                  color="primary"
                                  className={classes.buttonResize}
                                  onClick={() => startDownloadReport(period)}
                                >
                                  <Save />
                                </Fab>
                              )}
                              {loadingDownload && selectedReport === period && (
                                <CircularProgress
                                  size={39}
                                  className={classes.fabProgress}
                                />
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="overline" display="block" gutterBottom>
                Loading...
              </Typography>
            )}
          </CardContent>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default Report;
