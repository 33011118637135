// ##############################
// // // javascript library for creating charts
// #############################
// var Chartist = require("chartist");

// // ##############################
// // // // variables used to create animation on charts
// // #############################
// var delays = 80,
//   durations = 500;
// var delays2 = 80,
//   durations2 = 500;

// // ##############################
// // // // Daily Sales
// // #############################

// const dailySalesChart = {
//   data: {
//     labels: ["M", "T", "W", "T", "F", "S", "S"],
//     series: [[12, 17, 7, 17, 23, 18, 38]],
//   },
//   options: {
//     lineSmooth: Chartist.Interpolation.cardinal({
//       tension: 0,
//     }),
//     low: 0,
//     high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
//     chartPadding: {
//       top: 0,
//       right: 0,
//       bottom: 0,
//       left: 0,
//     },
//   },
//   // for animation
//   animation: {
//     draw: function (data) {
//       if (data.type === "line" || data.type === "area") {
//         data.element.animate({
//           d: {
//             begin: 600,
//             dur: 700,
//             from: data.path
//               .clone()
//               .scale(1, 0)
//               .translate(0, data.chartRect.height())
//               .stringify(),
//             to: data.path.clone().stringify(),
//             easing: Chartist.Svg.Easing.easeOutQuint,
//           },
//         });
//       } else if (data.type === "point") {
//         data.element.animate({
//           opacity: {
//             begin: (data.index + 1) * delays,
//             dur: durations,
//             from: 0,
//             to: 1,
//             easing: "ease",
//           },
//         });
//       }
//     },
//   },
// };

// // ##############################
// // // // Email Subscriptions
// // #############################

// const emailsSubscriptionChart = {
//   data: {
//     labels: [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "Mai",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sep",
//       "Oct",
//       "Nov",
//       "Dec",
//     ],
//     series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]],
//   },
//   options: {
//     axisX: {
//       showGrid: false,
//     },
//     low: 0,
//     high: 1000,
//     chartPadding: {
//       top: 0,
//       right: 5,
//       bottom: 0,
//       left: 0,
//     },
//   },
//   responsiveOptions: [
//     [
//       "screen and (max-width: 640px)",
//       {
//         seriesBarDistance: 5,
//         axisX: {
//           labelInterpolationFnc: function (value) {
//             return value[0];
//           },
//         },
//       },
//     ],
//   ],
//   animation: {
//     draw: function (data) {
//       if (data.type === "bar") {
//         data.element.animate({
//           opacity: {
//             begin: (data.index + 1) * delays2,
//             dur: durations2,
//             from: 0,
//             to: 1,
//             easing: "ease",
//           },
//         });
//       }
//     },
//   },
// };

// // ##############################
// // // // Completed Tasks
// // #############################

// const completedTasksChart = {
//   data: {
//     labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
//     series: [[230, 750, 450, 300, 280, 240, 200, 190]],
//   },
//   options: {
//     lineSmooth: Chartist.Interpolation.cardinal({
//       tension: 0,
//     }),
//     low: 0,
//     high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
//     chartPadding: {
//       top: 0,
//       right: 0,
//       bottom: 0,
//       left: 0,
//     },
//   },
//   animation: {
//     draw: function (data) {
//       if (data.type === "line" || data.type === "area") {
//         data.element.animate({
//           d: {
//             begin: 600,
//             dur: 700,
//             from: data.path
//               .clone()
//               .scale(1, 0)
//               .translate(0, data.chartRect.height())
//               .stringify(),
//             to: data.path.clone().stringify(),
//             easing: Chartist.Svg.Easing.easeOutQuint,
//           },
//         });
//       } else if (data.type === "point") {
//         data.element.animate({
//           opacity: {
//             begin: (data.index + 1) * delays,
//             dur: durations,
//             from: 0,
//             to: 1,
//             easing: "ease",
//           },
//         });
//       }
//     },
//   },
// };

// ##############################
// // // Sales Chart Data
// #############################

const salesChartData = {
  options: {
    chart: {
      id: "sales-chart",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Sales",
      align: "left",
      style: {
        fontSize: "18px",
        fontWeight: "normal",
        color: "black",
      },
    },
    subtitle: {
      text: "Last 30 Days",
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        color: "#9699a2",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "Sales in ₦",
      },
    },
    legend: {
      enabled: true,
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    markers: {
      size: 3,
    },
  },
  series: [
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ],
};

// ##############################
// // // Sales Chart Data
// #############################

const performanceChartData = {
  series: [50, 5, 20],
  options: {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        // offsetY: 40,
        expandOnClick: true,
        donut: {
          size: "65%",
          labels: {
            show: true,
            total: {
              showAlways: false,
              show: true,
            },
          },
        },
      },
    },
    labels: ["SUCCESSFUL", "FAILED", "PENDING"],
    dataLabels: {
      enabled: true,
    },
    states: {
      hover: {
        filter: "none",
      },
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "10px",
    },
    title: {
      text: "Performance",
      align: "left",
      style: {
        fontSize: "20px",
        fontWeight: "normal",
        color: "black",
      },
    },
    subtitle: {
      text: "Transaction Success Rate",
      align: "left",
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        color: "#9699a2",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  },
};

module.exports = {
  // dailySalesChart,
  // emailsSubscriptionChart,
  // completedTasksChart,
  salesChartData,
  performanceChartData,
};
