export const ON_BEGIN_TRANSACTIONS_FETCH = "ON_BEGIN_TRANSACTIONS_FETCH";
export const ON_TRANSACTIONS_FETCH_SUCCESS = "ON_TRANSACTIONS_FETCH_SUCCESS";
export const ON_MORE_TRANSACTIONS_FETCH_SUCCESS =
  "ON_MORE_TRANSACTIONS_FETCH_SUCCESS";
export const ON_TRANSACTIONS_FETCH_FAILED = "ON_TRANSACTIONS_FETCH_FAILED";
export const CLEAR_TRANSACTIONS = "CLEAR_TRANSACTIONS";

export const GET_SINGLE_TRANSACTION = "GET_SINGLE_TRANSACTION";
export const GET_SINGLE_TRANSACTION_FAILED = "GET_SINGLE_TRANSACTION_FAILED";
export const GET_SINGLE_TRANSACTION_SUCCESS = "GET_SINGLE_TRANSACTION_SUCCESS";

export const ON_OPEN_TRANSACTION = "ON_OPEN_TRANSACTION";
export const CLOSE_TRANSACTION = "CLOSE_TRANSACTION";
