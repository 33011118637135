import * as types from "./userTypes";

const initialState = {
  loading: false,
  error: null,
  loggedIn: false,
  userData: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ON_BEGIN_LOGIN:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case types.ON_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
        loggedIn: true,
      };

    case types.ON_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loggedIn: false,
      };

    case types.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};
export default userReducer;
