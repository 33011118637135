import * as types from "./transactionTypes";

const initialState = {
  loading: false,
  errors: null,
  showMenu: false,
  transactions: [],
  loadingSingle: false,
  singleTransaction: {},
  singleTxnError: "",
  txnId: "",
  modalOpen: false,
  pages: 1,
};

const transactionsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case types.ON_BEGIN_TRANSACTIONS_FETCH:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case types.ON_TRANSACTIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: payload.txns,
        pages: payload.count,
      };
    case types.ON_MORE_TRANSACTIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: [...state.transactions, ...payload.txns],
      };

    case types.ON_TRANSACTIONS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        errors: payload,
      };

    case types.CLEAR_TRANSACTIONS:
      return {
        ...state,
        transactions: [],
      };

    case types.GET_SINGLE_TRANSACTION:
      return {
        ...state,
        loadingSingle: true,
        singleTxnError: "",
      };

    case types.GET_SINGLE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loadingSingle: false,
        singleTransaction: payload,
      };

    case types.GET_SINGLE_TRANSACTION_FAILED:
      return {
        ...state,
        loadingSingle: false,
        singleTxnError: payload,
      };

    case types.ON_OPEN_TRANSACTION:
      return {
        ...state,
        txnId: payload,
        modalOpen: true,
      };

    case types.CLOSE_TRANSACTION:
      return {
        ...state,
        modalOpen: false,
      };

    default:
      return state;
  }
};
export default transactionsReducer;
