import * as types from "./userTypes";

import {
  axiosWithoutAuth,
  setToken,
  setGateway,
  clearLocalStorage,
} from "../../utils/auth";

export const attemptAuth = ({ email = "", password = "" }) => async (
  dispatch
) => {
  dispatch({ type: types.ON_BEGIN_LOGIN });

  try {
    const { data } = await axiosWithoutAuth().post(`/login`, {
      phone: email,
      password,
    });
    const { gateways } = data.data;
    setGateway(gateways);
    setToken(data.data);
    dispatch({
      type: types.ON_LOGIN_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: types.ON_LOGIN_FAILED,
      payload: error,
    });
  }
};

export const logout = () => (dispatch) => {
  clearLocalStorage();
  dispatch({ type: types.LOGOUT });
};
