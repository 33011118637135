// @material-ui/icons
import { Dashboard, Person, Assessment } from "@material-ui/icons";

// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import TableList from "views/TableList/TableList.js";
import Reports from "views/Reports/Report";
import Login from "views/Auth/Login";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/table",
    name: "Transactions",
    icon: "content_paste",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reports",
    icon: Assessment,
    component: Reports,
    layout: "/admin",
  },

  {
    path: "/logout",
    name: "Logout",
    icon: Person,
    component: Login,
    layout: "/auth",
  },
];

export default dashboardRoutes;
