import axios from "axios";
import { format, formatDistanceStrict, differenceInSeconds } from "date-fns";

// Constants

export const TYPE_ELECTRICITY = "ELECTRICITY";
export const TYPE_AIRTIME = "AIRTIME";
export const TYPE_TV = "TV";
export const TYPE_DATA = "DATA";

export const VERTICAL_TYPES = {
  ELECTRICITY: "ELECTRICITY",
  AIRTIME: "AIRTIME",
  TV: "TV",
  DATA: "DATA",
};

export const NETWORKS = [
  { code: "glo", name: "GLO" },
  { code: "mtn", name: "MTN" },
  { code: "airtel", name: "AIRTEL" },
  { code: "etisalat", name: "ETISALAT" },
];

export const DISCOS = [
  { code: 1, name: "ABUJA" },
  { code: 9, name: "KADUNA" },
  { code: 5, name: "JOS" },
  { code: 13, name: "PH" },
  { code: 17, name: "IKEJA" },
  { code: 18, name: "EKO" },
  { code: 19, name: "IBADAN" },
  { code: 23, name: "ENUGU" },
  { code: 36, name: "KANO" },
  { code: 39, name: "BH" },
  { code: 40, name: "PROTOGY" },
  { code: 41, name: "PHISBOND" },
  { code: 47, name: "ACCESSPOWER" },
];

export const SERVICE_PROVIDERS = [
  { name: "DSTV", code: "DSTV" },
  { name: "GOTV", code: "GOTV" },
  { name: "StarTimes", code: "STARTIMES" },
];

export const TV_GATEWAYS = [
  { name: "PAYU", code: "PAYU" },
  { name: "ITEX", code: "ITEX" },
];

export const DATA_GATEWAYS = [{ name: "ITEX", code: "ITEX" }];

export const AIRTIME_GATEWAYS = [{ name: "ITEX", code: "ITEX" }];

export const PAYMENT_MODES = [
  { name: "BANK TRANSFER", value: "BANK_TRANSFER" },
  { name: "CASH", value: "CASH" },
  { name: "MCASH", value: "MCASH" },
  { name: "ONLINE", value: "ONLINE" },
  { name: "QMCASH", value: "QMCASH" },
  { name: "RAVE", value: "RAVE" },
  { name: "CARD", value: "CARD" },
  { name: "KWIK", value: "KWIK" },
  { name: "MONNIFY", value: "INSTANT_TRANSFER" },
];

export const BANKS = [
  { name: "GTB", value: "GTB" },
  { name: "ABUJA ACCESS BANK", value: "ACCESS_ABUJA" },
  { name: "KADUNA ACCESS BANK", value: "ACCESS_KADUNA" },
  { name: "KADUNA GTB", value: "GTB_KADUNA" },
  { name: "JOS ACCESS BANK", value: "ACCESS_JOS" },
  { name: "UBA", value: "UBA" },
];

export const GATEWAYS = {
  ABUJA: [
    { name: "KALLAK", value: "KALLAK" },
    { name: "ITEX", value: "ITEX" },
    { name: "PAGA", value: "PAGA" },
    { name: "PROTOGY", value: "PROTOGY" },
    { name: "PHISBOND", value: "PHISBOND" },
  ],
  JOS: [{ name: "Adroit", value: "Adroit" }],
  KADUNA: [{ name: "ITEX", value: "ITEX" }],
  KANO: [
    { name: "ITEX", value: "ITEX" },
    { name: "FETS", value: "FETS" },
  ],
  IBADAN: [
    { name: "ITEX", value: "ITEX" },
    { name: "FETS", value: "FETS" },
  ],
  EKO: [
    { name: "ITEX", value: "ITEX" },
    { name: "BAXI", value: "BAXI" },
  ],
  IKEJA: [
    { name: "ITEX", value: "ITEX" },
    { name: "FETS", value: "FETS" },
    { name: "BAXI", value: "BAXI" },
  ],
  ENUGU: [
    { name: "ITEX", value: "ITEX" },
    { name: "FETS", value: "FETS" },
    { name: "EEDC", value: "EEDC" },
  ],
  RIVERS: [{ name: "ITEX", value: "ITEX" }],
  PH: [
    { name: "ITEX", value: "ITEX" },
    { name: "EasyPay", value: "EasyPay" },
  ],
  MTN: [
    { name: "ITEX", value: "ITEX" },
    { name: "PAYU", value: "PAYU" },
  ],
  BH: [{ name: "BuyPower Vending System", value: "BPVS" }],
  PROTOGY: [{ name: "PROTOGY", value: "PROTOGY" }],
  PHISBOND: [{ name: "PHISBOND", value: "PROTOGY" }],
  ACCESSPOWER: [{ name: "ACCESSPOWER", value: "ACCESSPOWER" }],
};

// Functions

// Admin Login Function
export const authenticateAdmin = () =>
  new Promise((resolve, reject) => {
    let authenticated = localStorage.getItem("auth");
    if (authenticated) return resolve();

    const phone = prompt("Enter Username");
    const password = prompt("Enter Password");

    callApi("/login", { phone, password }, "post")
      .then(({ user }) => {
        localStorage.setItem("auth", JSON.stringify(user));
        return resolve(user);
      })
      .catch(reject);
  });

// Logout
export const logoutAdmin = () => {
  localStorage.clear("auth");
  callApi("/logout").then(() => {
    window.location = "/";
  });
};

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

// Date Functions
export const formatDate = (date) => formatDateTime(date);

/** Formate date util with timezone offset calculation
export const formatDate = (date) => {
  const timezoneDate = new Date(date);
  const minutes = new Date(timezoneDate).getTimezoneOffset();
  timezoneDate.setMinutes(timezoneDate.getMinutes() + minutes);

  return format(timezoneDate, 'DD-MM-YYYY  h:mm A');
};
*/

export const formatDay = (date) => format(date, "DD-MM-YYYY");

export const formatDateTime = (date) => {
  return `${new Date(date).toLocaleString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })} • ${new Date(date).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Africa/Accra",
  })}`;
};

export const getDuration = (timeA = new Date(), timeB = new Date()) =>
  formatDistanceStrict(timeA, timeB);

export const getDurationSeconds = (timeA = new Date(), timeB = new Date()) =>
  differenceInSeconds(timeA, timeB);

export const isValidPhone = (n) => {
  var firstChar;
  var number;
  var pattern = /^([0]{1})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7,8})$/g;

  if (!n || n.length < 5) return false;

  if (typeof n === "number") {
    // numbers never begin with 0, force this to become a string
    number = "0" + n;
  } else if (typeof n === "string") {
    firstChar = n.substring(0, 1);

    // user may supply 0 before the number or not
    // e.g 0703 or 703 (two types of people ¯\_(ツ)_/¯)
    // either way supply missing leading 0
    number = firstChar === "0" ? n : "0" + n;
  } else {
    return false;
  }

  // remove all whitespace(s) before running test
  return pattern.test(number.replace(/\s+/g, ""));
};

export const splitPhoneNumber = (num = "08000000000") => {
  num = String(num).replace(/[^0-9.]+/g, "");
  return `${num.slice(0, 4)}-${num.slice(4, 7)}-${num.slice(7)}`;
};

export const commaNumber = (num = 0) => Number(num).toLocaleString("en-US");

export const formatAmount = (num) => `₦ ${commaNumber(num)}`;

export const _toDash = (str = "") => str.replace(/_/g, "-");

export const formatToken = (str) => {
  if (!str) return "";
  str = String(str);
  if (str.indexOf("-") !== -1) return str;
  str = str.toString();
  let totalSplit = Math.floor(str.length / 4);
  let start = 0;
  let end = 4;
  let output = "";
  for (let i = 0; i < totalSplit; i++) {
    output += str.slice(start, end);
    output += i === totalSplit - 1 ? "" : "-";
    start += 4;
    end += 4;
  }
  return output.trim("-");
};

// Get value from url query parameter
export const getUrlParameter = (key, params) => {
  key = key.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + key + "=([^&#]*)");
  let results = regex.exec(params);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

const request = axios.create({
  baseURL: "/",
  timeout: 120000, // 1 Minute
});

export const callApi = (endpoint, data = {}, method = "get") => {
  method = method.toLowerCase();
  method = ["post", "get", "put", "patch"].includes(method) ? method : "post";
  // console.log(
  //   'Request Route: ',
  //   endpoint + '?' + qs.stringify(data) + ` Method: ${method.toUpperCase()}`
  // );
  return new Promise((resolve, reject) => {
    const sendData = method === "get" ? { params: data } : { ...data };
    request[method](endpoint, sendData)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) {
          // Response from server w/ error
          return reject(err.response.data);
        } else {
          // We would normally reject with an Error object here
          // But We use destructuring to display error toaster and the message object is lost in the process
          // Return a direct object with message and title properties
          return reject({
            message: "Please check your internet connection and try again.",
            title: "❌ Network Error",
          });
        }
      });
  });
};

// Check if screen size is mobile or desktop
export const isMobile = () => {
  if (window.screen) {
    return window.screen.width < 1024;
  }
  // Default fallback;
  return true;
};

// Get nested object values without errors
export const getNestedObject = (nestedObj, pathArr) =>
  pathArr.reduce(
    (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
    nestedObj
  );

export const isSuperAdmin = () => {
  // Check logged in user
  const auth = JSON.parse(localStorage.getItem("auth"));
  if (
    ![
      // 34773,
      // 184912,
      // 7,
      // 503447,
      // 925553,
      // 28843, // Tunde
      // 1208457,
      // 83261, // Victoria
      7, // Abiola Shedrack?
      28843, // Tunde
      83261, // Victoria
      419005, // Olamide
      // 1208457, // Samuel
      1421772, // Azeez
      1118326, // Maaruf
    ].includes(auth.id)
  )
    return false;

  return true;
};

export const isFinance = () => {
  // Check logged in user
  const auth = JSON.parse(localStorage.getItem("auth"));
  if (
    ![
      34773,
      184912,
      7,
      280842,
      164,
      21,
      28843,
      925553,
      1118326,
      1028273,
      28843,
      516575, // Izu
    ].includes(auth.id)
  )
    return false;

  return true;
};

export const isSupportAdmin = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  if (
    ![
      286,
      28843,
      925553,
      1208457,
      1118326,
      992537,
      28843,
      419005,
      1421772,
    ].includes(auth.id)
  )
    return false;
  return true;
};

export const getSupervisor = async () => {
  try {
    const { id, name } = await callApi("/supervisor");

    return { id, name };
  } catch (err) {
    return false;
  }
};

export const isShiftSupervisor = () => {
  try {
    return getSupervisor()
      .then(({ id, name }) => {
        const auth = JSON.parse(localStorage.getItem("auth"));
        window.currentSupervisor = name || "NONE";
        if (id !== auth.id) return false;

        return true;
      })
      .catch(() => false);
  } catch (err) {
    return false;
  }
};
