export const BEGIN_REPORT_FETCH = "BEGIN_REPORT_FETCH";
export const BEGIN_REPORT_DOWNLOAD = "BEGIN_REPORT_DOWNLOAD";

export const REPORT_FETCH_SUCCESS = "REPORT_FETCH_SUCCESS";
export const REPORTS_FETCH_SUCCESS = "REPORTS_FETCH_SUCCESS";
export const REPORT_DOWNLOAD_SUCCESS = "REPORT_DOWNLOAD_SUCCESS";

export const REPORT_DOWNLOAD_COMPLETE = "REPORT_DOWNLOAD_COMPLETE";
export const CLEAR_DOWNLOADS = "CLEAR_DOWNLOADS";

export const REPORT_FETCH_FAILED = "REPORT_FETCH_FAILED";
export const REPORT_DOWNLOAD_FAILED = "REPORT_DOWNLOAD_FAILED";
export const CLEAR_REPORTS = "CLEAR_REPORTS";
