import * as types from "./dashboardTypes";

const initialState = {
  loading: false,
  errors: null,
  showMenu: false,
  performance: {},
  sales: {},
  score: {},
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ON_BEGIN_SUMMARY_FETCH:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case types.ON_SUMMARY_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        performance: action.payload.performance,
        sales: action.payload.sales,
        score: action.payload.score,
      };

    case types.ON_SUMMARY_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};
export default dashboardReducer;
