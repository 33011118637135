import * as types from "./dashboardTypes";

import { axiosWithAuth } from "../../utils/auth";

export const getSummary = () => async (dispatch) => {
  dispatch({ type: types.ON_BEGIN_SUMMARY_FETCH });

  try {
    const [
      { data: salesData },
      { data: scoreData },
      { data: performanceData },
    ] = await Promise.all([
      axiosWithAuth().get(`/stats/sales`, {
        params: { view: "MONTH", precision: "DAILY" },
      }),
      axiosWithAuth().get(`/stats/scores`, {
        params: { view: "MONTH" },
      }),
      axiosWithAuth().get(`/stats/performance`, {
        params: { view: "MONTH" },
      }),
    ]);
    dispatch({
      type: types.ON_SUMMARY_FETCH_SUCCESS,
      payload: {
        score: scoreData.data,
        sales: salesData.data,
        performance: performanceData.data,
      },
    });
  } catch (err) {
    dispatch({
      type: types.ON_SUMMARY_FETCH_FAILED,
      payload: err,
    });
  }
};
