import * as types from "./transactionTypes";

import { axiosWithAuth } from "../../utils/auth";

export const getTransactions = (params, where) => async (dispatch) => {
  dispatch({ type: types.ON_BEGIN_TRANSACTIONS_FETCH });
  console.log("params", params);
  console.log("where", where);
  try {
    const { data } = await axiosWithAuth().get(`/transactions`, {
      params: { ...params, where },
    });
    const pages = Math.ceil(data.data.count / 30);

    dispatch({
      type: types.ON_TRANSACTIONS_FETCH_SUCCESS,
      payload: { txns: data.data.rows, count: pages },
    });
  } catch (err) {
    dispatch({
      type: types.ON_TRANSACTIONS_FETCH_FAILED,
      payload: err,
    });
  }
};
export const getMoreTransactions = (params, where) => async (dispatch) => {
  dispatch({ type: types.ON_BEGIN_TRANSACTIONS_FETCH });
  try {
    const { data } = await axiosWithAuth().get(`/transactions`, {
      params,
      where,
    });

    dispatch({
      type: types.ON_MORE_TRANSACTIONS_FETCH_SUCCESS,
      payload: { txns: data.data.rows },
    });
  } catch (err) {
    dispatch({
      type: types.ON_TRANSACTIONS_FETCH_FAILED,
      payload: err,
    });
  }
};

export const clearTransactions = () => (dispatch) => {
  dispatch({ type: types.CLEAR_TRANSACTIONS });
};

export const getSingleTransaction = (id) => async (dispatch) => {
  dispatch({ type: types.GET_SINGLE_TRANSACTION });

  try {
    const { data } = await axiosWithAuth().get(`/transactions/${id}`);
    dispatch({
      type: types.GET_SINGLE_TRANSACTION_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: types.GET_SINGLE_TRANSACTION_FAILED,
      payload: err,
    });
  }
};

export const openTransaction = (params) => (dispatch) => {
  dispatch({ type: types.ON_OPEN_TRANSACTION, payload: params.id });
};

export const closeTransaction = () => (dispatch) => {
  dispatch({ type: types.CLOSE_TRANSACTION });
};
