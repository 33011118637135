import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { Icon, Box } from "@material-ui/core";
// @material-ui/icons
import { Store } from "@material-ui/icons";
//  Custom Component
import { GridItem, GridContainer } from "components/Grid";
import {
  Card,
  CardHeader,
  CardIcon,
  CardBody,
  CardFooter,
} from "components/Card";
//  Functions & Data
import { getSummary } from "../../modules/dashboard/dashboardActions";
import { formatAmount } from "utils";
import { salesChartData, performanceChartData } from "variables/charts.js";
// Design Components/Data
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Loading from "views/TableList/Loading";

const useStyles = makeStyles(styles);

function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sales, performance, score, loading } = useSelector(
    (state) => state.dashboard
  );

  const [lineChartSalesData, setLineChartSalesData] = useState(salesChartData);
  const [donutChartPerformanceData, setDonutChartPerfomrmanceData] = useState(
    performanceChartData
  );

  useEffect(() => {
    dispatch(getSummary());
  }, []);

  useEffect(() => {
    setLineChartSalesData({
      series: [
        {
          name: "Total Sales (₦)",
          data: Object.values(sales).map((l) => `${parseFloat(l).toFixed(2)}`),
        },
      ],
      options: {
        ...lineChartSalesData.options,
        xaxis: {
          ...lineChartSalesData.options.xaxis,
          categories: Object.keys(sales).map((key) => {
            const date = new Date(key);
            const day = date.getDate();
            const month = date.toLocaleString("default", {
              month: "short",
            });

            return `${month} ${day}`;
          }),
        },
      },
    });
  }, [sales]);

  useEffect(() => {
    setDonutChartPerfomrmanceData({
      series: Object.values(performance).map((data) => data),
      options: {
        ...donutChartPerformanceData.options,
        labels: Object.keys(performance).map((key) => key),
      },
    });
  }, [performance]);

  const getDashboardfSummary = () => dispatch(getSummary());
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p
                onClick={getDashboardfSummary}
                className={classes.cardCategory}
              >
                Total Amount
              </p>
              <h3 className={classes.cardTitle}>
                <small>
                  {score && score.sales
                    ? formatAmount(score.sales)
                    : formatAmount(0)}
                </small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Updated Hourly</div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>
                Total Transactions for Last 30 Days
              </p>
              <h3 className={classes.cardTitle}>
                <small>{score && score.count ? score.count : 0}</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Updated Hourly</div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card chart>
            <CardBody className={classes.chart}>
              <Chart
                options={lineChartSalesData.options}
                series={lineChartSalesData.series}
                type="line"
                height="500px"
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardBody className={classes.chart}>
              <Chart
                options={donutChartPerformanceData.options}
                series={donutChartPerformanceData.series}
                type="donut"
                height="500px"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Box display="flex" justifyContent="center">
        {loading && <Loading />}
      </Box>
    </div>
  );
}
export default Dashboard;
