import React, { useEffect } from "react";

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";

import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";

import store from "./store";
import Login from "views/Auth/Login";
import PrivateRoute from "./utils/PrivateRoute";
import "assets/css/material-dashboard-react.css?v=1.9.0";

const hist = createBrowserHistory();
const App = () => {
  useEffect(() => {}, []);

  return (
    <>
      <Provider store={store}>
        <Router history={hist}>
          <Switch>
            <PrivateRoute path="/admin" component={Admin} />
            <Route path="/rtl" component={RTL} />
            <Route path="/auth" component={Login} />
            <Redirect from="/" to="/auth" />
          </Switch>
        </Router>
      </Provider>
    </>
  );
};

// export default connect()(App);
export default App;
